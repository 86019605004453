/**********/
/* Buttons
/**********/


//Alternative mixin to Bootstrap button-varient mixin
@mixin new-button($color, $background, $border) {
	display: inline-block;
	@include font-btn($color);
	background-color: $background;
	background-repeat: no-repeat;
	background-position-x: -100px;
	border-width: 2px;
	border-style: solid;
	border-color: $border;
	border-radius: 5px;
	padding: 6px 15px;
	text-align: center;
	opacity: 1;
	@include transition(bounce);
	
	@include screen(lg) {
	    padding: 15px 60px;
	}
	
	&:hover,
	&:focus,
	&.focus,
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		text-decoration: none;
		color: $background !important;
		background-color: $color !important;
		@include transition(bounce);

		&.nohover {
			transform: inherit;
		}
	}
	
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		background-image: none;
		box-shadow: none;
		@include transition(bounce);
	}
	
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&.focus,
		&:active,
		&.active {
			background-color: lighten($background, 20);
			border-color: lighten($background, 20);
			cursor: not-allowed;
			
		}
 	}
	
	.badge {
		color: $background;
		background-color: $color;
	}
  
}



.btn {
	@include new-button( color(white), color(blue), color(blue));
}

.btn__white {
	@include new-button( color(blue), color(white), color(blue));
}

.btn__block {
	display: block !important;
}

.btn__dta_member {
	@include new-button( color(black), color(transparent), color(black));
	white-space: nowrap;
	padding: 8px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;

	@include fluid-type(map-get($grid-breakpoints, 'sm'), map-get($grid-breakpoints, 'xl2'), 11px, 15px);


	&::before {
		@include iconfont($icon-clock, 30px, color(white));
		@include color(black, background-color);
		padding: 1px;
		border-radius: 100%;
		@include transition(bounce);
		margin-right: 10px;
	}

	@include hover() {
		color: color(white) !important;
		background-color: color(black) !important;
		@include transition(bounce);

		&.nohover {
			transform: inherit;
		}

		&::before {
			@include color(white);
			@include transition(bounce);
		}
	}
}
.btn__small,
.tribe_events a.btn__small,
.tribe-common a.btn__small {
	padding: 5px 15px;
    @include font-size(14px);
    font-weight: 400;
}

.btn__transparent {
	@include new-button( color(blue), color(transparent), color(blue));

	@include hover() {
		color: color(white) !important;
		background-color: color(blue) !important;
		@include transition(bounce);

		&.nohover {
			transform: inherit;
		}
	}
}

.btn__transparent__white {
	@include new-button( color(white), color(transparent), color(white));

	@include hover() {
		color: color(blue) !important;
		background-color: color(white) !important;
		@include transition(bounce);

		&.nohover {
			transform: inherit;
		}
	}
}


@mixin button-group($divider-color) {
	@include screen(xs, only) {
		display: flex;
		flex-direction: column;

		& > a  {
		//	border-radius: 30px;
			margin-bottom: 15px;
		}
	}
	
	& > a {
		position: relative;
		padding-left: 30px;
		padding-right: 30px;

		@include screen(sm) {
			border-radius: 0;

			&:nth-child(n+2) {
				&::before {
					content: '';
					position: absolute;
					left: -2px;
					top: 50%;
					transform: translateX(-50%) translateY(-50%);
					display: block;
					width: 1px;
					height: 35%;
					background-color: $divider-color;
					@include transition(bounce);
				}
			}

			&:first-child {
				padding-left: 60px;
				border-top-left-radius: 30px;
				border-bottom-left-radius: 30px;
			}
	
			&:last-child {
				padding-right: 60px;
				border-top-right-radius: 30px;
				border-bottom-right-radius: 30px;
			}
		}

		@include hover() {
			&::before {
				opacity: 0;
				@include transition(bounce);
			}
			& + a {
				&::before {
					opacity: 0;
					@include transition(bounce);
				}	
			}
		}
	}
}

.tribe-common a.btn.btn__event-list {
	@include new-button( color(white), color(blue-dark), color(blue-dark));
	padding: 5px 15px !important;
    @include font-size(13px);
    font-weight: 400;
	margin-top: 30px;
}