@import '../../node_modules/normalize.scss/normalize.scss';

// Variables & Mixins
@import 'base/_variables.scss';
@import 'base/_mixins.scss';

// Partials
@import 'partials/iconfont';
@import 'partials/typography';
@import 'partials/buttons';

html {
	box-sizing: border-box;
	font-size:62.5%; //62.5% is recommended to we can easily use REM values and generate a px fallback.
}
body {
    @include color(blue, background-color);
}
.login {
    h1 {
        position: relative;
        height: 100px;
        margin: 0 auto;

        &::before {
            content: url(/wp-content/themes/downtownstjoemo/assets/media/images/site-logo-black-background.svg);
            position: absolute;
            bottom:0;
            left:50%;
            transform: translateX(-50%);
            display: block;
            width: 150px;
        }

        a {
            display: none;
            background-image: none;
        }
    }
}

.login {
    form {
        background: rgba(color(white), 0.2);
    }

    label,
    #backtoblog a,
    #nav a {
        @include color(white);
    }
}

.wp-core-ui .button-primary {
    @include color(gold, background-color);
    border: none;
    box-shadow: 0 0 0 0;
    text-shadow: none;
}