/*-----------*/
/* Variables
/*-----------*/

//Global Gri, variables
$global-config:	(
	block--width:	100%,
	width:			1600px,
	columns:		12,
	gutters:		15px,
	header-height:	95px,
);

$header-size: (
	xs:  75px,
	sm:  75px,
	md:  75px,
	lg:  75px,
	xl:  110px,
	xl2: 110px,
	xl3: 110px,
);

$logo-width: 180px;

$grid-breakpoints: (
	xs:  0,
	sm:  544px,
	md:  768px,
	lg:  992px,
	xl:  1200px,
	xl2: 1440px,
	xl3: 1600px,
);

//Colors
$colors: (
	black:			#222222,
	white:			#ffffff,
	charcoal:		#4d4d4d,
	blue:			#7CAFC4,
	blue-dark:		#356d85,
	ultralight:		#f9f7f7,
	gold:			#c6972f,
	gold-light:		#F4EBDA,
	rose:			#A26769,
	green:			#D0FFCE,
	gray:			#8C93A8,
	transparent:	transparent,
);

//Z-Index Management -- utilizes Sass Maps
//Easiest way to use is via the mixin: @include z-index(zero);
$z-index: (
	heaven					:	9000,
	social-share			:	5000,
	header--logo			:	3000,
	hamburger				:	2000,
	nav--mobile				:	1000,
	header					:	999,
	aboveground				:	100,
	ground					:	1,
	zero					:	0,
	underground				:	-1,
	hell					:	-9000
);

$transitions: (
	ease			: 	ease,
	linear			: 	linear,
	ease-in			: 	ease-in,
	ease-out		: 	ease-out,
	ease-in-out		: 	ease-in-out,
	step-start		: 	step-start,
	step-end		: 	step-end,

	// Special Effects
	bounce			:	cubic-bezier(0.68, -0.55, 0.265, 1.55),

	// Cubic
	easeInCubic		:	cubic-bezier(0.550, 0.055, 0.675, 0.190),
	easeOutCubic 	:	cubic-bezier(0.215, 0.610, 0.355, 1.000),
	easeInOutCubic	:	cubic-bezier(0.645, 0.045, 0.355, 1.000),

	// Circ
	easeInCirc   	:	cubic-bezier(0.600, 0.040, 0.980, 0.335),
	easeOutCirc  	:	cubic-bezier(0.075, 0.820, 0.165, 1.000),
	easeInOutCirc	:	cubic-bezier(0.785, 0.135, 0.150, 0.860),

	// Expo
	easeInExpo   	:	cubic-bezier(0.950, 0.050, 0.795, 0.035),
	easeOutExpo  	:	cubic-bezier(0.190, 1.000, 0.220, 1.000),
	easeInOutExpo	:	cubic-bezier(1.000, 0.000, 0.000, 1.000),

	// Quad
	easeInQuad     	:	cubic-bezier(0.550, 0.085, 0.680, 0.530),
	easeOutQuad    	:	cubic-bezier(0.250, 0.460, 0.450, 0.940),
	easeInOutQuad  	:	cubic-bezier(0.455, 0.030, 0.515, 0.955),

	// Quart
	easeInQuart    	:	cubic-bezier(0.895, 0.030, 0.685, 0.220),
	easeOutQuart   	:	cubic-bezier(0.165, 0.840, 0.440, 1.000),
	easeInOutQuart 	:	cubic-bezier(0.770, 0.000, 0.175, 1.000),

	// Quint
	easeInQuint    	:	cubic-bezier(0.755, 0.050, 0.855, 0.060),
	easeOutQuint   	:	cubic-bezier(0.230, 1.000, 0.320, 1.000),
	easeInOutQuint 	:	cubic-bezier(0.860, 0.000, 0.070, 1.000),

	// Sine
	easeInSine     	:	cubic-bezier(0.470, 0.000, 0.745, 0.715),
	easeOutSine    	:	cubic-bezier(0.390, 0.575, 0.565, 1.000),
	easeInOutSine  	:	cubic-bezier(0.445, 0.050, 0.550, 0.950),

	// Back
	easeInBack     	:	cubic-bezier(0.600, -0.280, 0.735, 0.045),
	easeOutBack    	:	cubic-bezier(0.175,  0.885, 0.320, 1.275),
	easeInOutBack  	:	cubic-bezier(0.680, -0.550, 0.265, 1.550),
);